body {
  background-color: #222831;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}
main {
  padding: 1em;
}

h1,
h2,
h3, h4, h5 {
  font-weight: 300;
  color: lightblue;
}

h5 {
margin: 0;
}


h3 {
  font-size: 1em;
  margin-top: 1em;
}

a {
  color: #e9715c;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
  color: lightseagreen;
}

p {
  color: #93ec73;
  font-size: 14px;
}


small {
  color: aliceblue;
  font-size: 0.75em;
}

.imports, .codeBlock {
  font-family: 'Courier New', Courier, monospace;
  margin: 5px 0px 5px 20px;
  color: #e9715c;
}

.codeBlock {
margin-top: 2em;
margin-bottom: 2em;
}


.codeBlock > h3 {
  margin: 0;
}

.codeBlock > div {
  margin-left: 1em;
}


.imports span, .codeBlock span {
  color: #93ec73;
}

.grid-1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto  auto;
}



/* items */




.aboutSection{
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 750px) {
  .grid-1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto  ;
    grid-gap: 10px;
  }
  .aboutSection {
    grid-template-columns: 1fr;
  }

  .codeBlock, .imports {
    margin-left: 0px;
  }

  h2 {
    font-size:1em;
  }
  .grid-1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto  ;
    grid-gap: 10px;
  }
  ul {
    margin-left: 0px;
    font-size: 0.75em;
  }
  .imports, .codeBlock  {
    font-size: 12px ;
  }
}


.aboutSection img{
  max-width: 250px;
  max-height: 250px;
  border-radius: 50%;
  margin-top: 40px;
  margin-bottom: 40px;
  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 10);
}